// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAaXpHoL3ZHk4qZLK9gYwiTnGtXPNNy21o',
  authDomain: 'handshaik-app.firebaseapp.com',
  projectId: 'handshaik-app',
  storageBucket: 'handshaik-app.appspot.com',
  messagingSenderId: '1008996407653',
  appId: '1:1008996407653:web:ecec7bcbf7af84e84ea98c',
  measurementId: 'G-MLNXF510YL'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Set persistence to LOCAL
setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error('Error setting persistence:', error);
});

export { auth }

