// src/components/Sidebar.js

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import './Sidebar.css';
import defaultProfilePic from '../images/default-profile-pic.webp'; // Import the default profile picture

const Sidebar = () => {
  const [firstName, setFirstName] = useState('');
  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const displayName = user.displayName || 'User';
      const firstName = displayName.split(' ')[0]; // Get the first name
      setFirstName(firstName);
      const hours = new Date().getHours();
      if (hours < 12) {
        setGreeting('Good morning');
      } else if (hours < 18) {
        setGreeting('Good afternoon');
      } else {
        setGreeting('Good evening');
      }
    }
  }, []);

  const handleLogout = () => {
    auth.signOut().then(() => {
      // Redirect to login or home page after logout
      window.location.href = '/login';
    });
  };

  return (
    <div className="sidebar">
      <div className="sidebar-content">
        <img src={defaultProfilePic} width="120" height="120" alt="Profile" className="profile-picture" />
        <h2>PROFILE</h2>
        <nav>
          <ul>
            <li><Link to="/welcome">Dashboard</Link></li>
            <li><Link to="/manage-team">Manage Team</Link></li>
            <li><Link to="/contacts">Contacts</Link></li>
            <li><Link to="/Deck">Client Deck</Link></li>
            <li><Link to="/calendar">Calendar</Link></li>
            <li><Link to="/charts/priorities">Priorities</Link></li>
            <li><Link to="/faq">FAQ Page</Link></li>
          </ul>
        </nav>
      </div>
      <div className="admin-bar">
        <div className="welcome-intro">
          <p>{greeting} {firstName}</p>
        </div>
        <div className="admin-bar-content">
          <button className="logout-button" onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
