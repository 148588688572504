// src/components/LoggedInHeader.js

import React from 'react';
import { Link } from 'react-router-dom';
import './LoggedInHeader.css';
import logo from '../images/handshaik-logo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBullseye, faUserFriends, faBell, faThLarge, faChartPie } from '@fortawesome/free-solid-svg-icons';

function LoggedInHeader() {
  return (
    <header className="LoggedInHeader">
      <div className="header-content">
        <div class="top-logo">
        <img src={logo} width={190} height={60} alt="Handshaik logo" className="main-logo" />
        </div>
        <nav className="nav">
          <ul>
            <li>
              <Link to="/welcome"><FontAwesomeIcon icon={faHome} /> Overview</Link>
            </li>
            <li>
              <Link to="/insights"><FontAwesomeIcon icon={faChartPie} /> Insights</Link>
            </li>
            <li>
              <Link to="/targets"><FontAwesomeIcon icon={faBullseye} /> Targets</Link>
            </li>
            <li>
              <Link to="/studio"><FontAwesomeIcon icon={faUserFriends} /> Studio</Link>
            </li>
            <li>
              <Link to="/alerts"><FontAwesomeIcon icon={faBell} /> Alerts</Link>
            </li>
            <li>
              <Link to="/integrations"><FontAwesomeIcon icon={faThLarge} /> Integrations</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default LoggedInHeader;
