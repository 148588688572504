// src/companiesHouse.js

import axios from 'axios';

const apiKey = process.env.REACT_APP_COMPANIES_HOUSE_API_KEY;
const baseURL = 'https://api.companieshouse.gov.uk';

const companiesHouseClient = axios.create({
  baseURL,
  auth: {
    username: apiKey,
    password: '', // Password is empty for Companies House API
  },
});

export const searchCompanies = async (query) => {
  try {
    const response = await companiesHouseClient.get(`/search/companies?q=${query}`);
    return response.data.items;
  } catch (error) {
    console.error('Error fetching data from Companies House API:', error);
    return [];
  }
};
