// src/components/Welcome.js

import React from 'react';
import './Welcome.css';
import Card from './Card';

const Welcome = () => {
  return (
    <div className="welcome-page"> 
      <div class="welcome-container">
        <div className="header">
          <div className="header-left">
            <button className="saved-searches">My Saved Searches</button>
          </div>
          <div className="header-right">
            <span>Sort on</span>
            <button className="sort-button">Lowest</button>
          </div>
        </div>
        <div className="content">
          <Card 
            title="Trend Reports"
            description="Regularly updated reports on emerging trends and industry shifts."
          />
          <Card 
            title="Competitor Analysis"
            description="Detailed comparisons between your company and key competitors."
          />
          <Card 
            title="Financial Insights"
            description="Analysis of financial statements and performance indicators."
          />
          <Card 
            title="Customer Behaviour"
            description="Insights into customer demographics, preferences, and buying behaviour."
          />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
