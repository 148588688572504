// src/components/Insights.js

import React from 'react';
import InsightsSearch from './InsightsSearch';

const Insights = () => {
  return (
    <div>
      <h1>Insights</h1>
      <InsightsSearch />
    </div>
  );
};

export default Insights;
