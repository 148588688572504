// src/components/InsightsSearch.js

import React, { useState } from 'react';
import axios from 'axios';
import { searchCompanies } from '../companiesHouse';
import './InsightsSearch.css';

const InsightsSearch = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  const handleSearch = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000/api/openai';
      const gptResponse = await axios.post(`${apiUrl}/openai`, {
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: 'You are a helpful assistant.',
          },
          {
            role: 'user',
            content: `Interpret the following query for searching companies in the Companies House database: "${query}"`,
          },
        ],
        max_tokens: 100,
      });
  
      const interpretedQuery = gptResponse.data.choices[0].message.content.trim();
      const companies = await searchCompanies(interpretedQuery);
  
      setResults(companies);
    } catch (error) {
      console.error('Error processing search:', error);
    }
  };

  return (
    <div className="insights-search-container">
      <h2>Business Insights Search</h2>
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Ask a business-related question..."
        className="insights-search-input"
      />
      <button onClick={handleSearch} className="insights-search-button">Search</button>
      <div className="insights-search-results">
        {results.length > 0 && (
          <ul>
            {results.map((company) => (
              <li key={company.company_number}>
                {company.title} - {company.company_number}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default InsightsSearch;
