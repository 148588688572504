// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import logo from './handshaik-logo-white.webp';
import './App.css';
import Login from './components/Login';
import Register from './components/Register';
import Welcome from './components/Welcome';
import Insights from './components/Insights';
import LoggedInHeader from './components/LoggedInHeader';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className={`App ${isAuthenticated ? 'App-white-bg' : 'App-dark-bg'}`}>
        {isAuthenticated ? (
          <>
            <Sidebar />
            <div className="main-content">
              <LoggedInHeader />
              <Routes>
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/insights" element={<Insights />} />
              </Routes>
              <Footer />
            </div>
          </>
        ) : (
          <>
            <header className="App-header">
              <img src={logo} className="App-logo" alt="logo" />
              <nav className="nav">
                <ul>
                  <li>
                    <a href="/login">Login</a>
                  </li>
                  <li>
                    <a href="/register">Register</a>
                  </li>
                </ul>
              </nav>
            </header>
            <Routes>
              <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
              <Route path="/register" element={<Register />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
